<template>
  <div class="container">
    <div class="content">
      <div class="sub-head">{{ subTitle }}</div>
      <div class="sub-des">
        {{ subDes }}
      </div>
    </div>
    <div class="contact">
      <div class="con-title">{{ conTitle }}</div>
      <div class="con-des">
        {{ conDes }}<a href="mailto:sadhilanandstudio@gmail.com">{{ email }}</a>
      <div class="copyright">Copyright©RE PEAK (HK) CO., LIMITED</div>

      </div>
    </div>
    <div class="footer">
      <span>Contact Us</span>
      <a href="mailto:sadhilanandstudio@gmail.com">sadhilanandstudio@gmail.com</a>
      <span class="copyright">Copyright©RE PEAK (HK) CO., LIMITED</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutFooter",
  data() {
    return {
      subTitle: "ShareKaro Is A Popular File Sharing App",
      subDes:
        "ShareKaro is a popular file sharing app, fast and no data usage. You can share all kinds of files to Android/iOS/PC/Jio users, including videos, apps, photos, music, etc.",
      conTitle: "Contact Us",
      conDes: "If you meet any problem, please feel free to contact us ",
      email: "sadhilanandstudio@gmail.com",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";

.container {
  background: #ffffff;
  .content {
    height: vw(480);
    background: linear-gradient(270deg, #30c7ff 0%, #2f9cf6 100%);
    text-align: center;
    padding: vw(105) vw(240);
    .sub-head {
      //width: vw(934);
      font-size: vw(50);
      font-family: HelveticaNeue-Medium, HelveticaNeue;
      font-weight: 500;
      color: #ffffff;
      line-height: vw(61);
      
    }
    .sub-des {
      font-size: vw(24);
      font-family: HelveticaNeue;
      color: #ffffff;
      line-height: vw(32);
      margin: vw(17) vw(100) vw(110);
      //padding: 0 10px;
    }
  }
  .contact {
    width: vw(1120);
    height: vw(250);
    background: #ffffff;
    box-shadow: vw(0) vw(6) vw(50) vw(0) rgba(0, 0, 0, 0.09);
    border-radius: vw(8);
    margin: 0 vw(240);
    padding: vw(70) vw(44);
    position: relative;
    top: vw(-125);
    //top: -125;
    .con-title {
      font-size: vw(38);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: vw(53);
      text-align: center;
    }
    .con-des {
      font-size: vw(28);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #757575;
      line-height: vw(40);
      text-align: center;
      padding-top: vw(17);
      a {
        color: #2f9cf6;
      }
    }
  }
  .footer {
    .copyright{
        padding-left: maxPx(20);
    }
    height: vw(61);
    text-align: center;
    line-height: vw(61);
    background: #f4f9ff;
    font-size: vw(14);
    font-family: HelveticaNeue;
    span {
      color: #666666;
    }
    a {
      margin-left: vw(24);
      color: #333333;
      text-decoration: underline;
    }
  }
}
@media screen and (max-width: 1080px) {
.container {
  width: minPx(1600);
  .content {
    width: minPx(1600);
    height: minPx(480);
    padding: minPx(105) minPx(240);
    .sub-head {
      //width: minPx(934);
      font-size: minPx(50);
      line-height: minPx(61);
    }
    .sub-des {
      width: minPx(1000);
      font-size: minPx(24);
      line-height: minPx(32);
      margin: minPx(17) minPx(93) minPx(110);
    }
  }
  .contact {
    width: minPx(1120);
    height: minPx(250);
    box-shadow: minPx(0) minPx(6) minPx(50) minPx(0) rgba(0, 0, 0, 0.09);
    border-radius: minPx(8);
    margin: 0 minPx(240);
    top: minPx(-125);
    .con-title {
      font-size: minPx(38);
      line-height: minPx(53);
    }
    .con-des {
      font-size: minPx(28);
      line-height: minPx(40);
      padding-top: minPx(17);
    }
  }
  .footer {
    .copyright{
        padding-left: axP;
    }
    width: minPx(1600);
    height: minPx(61);
    line-height: minPx(61);
    font-size: minPx(14);
    a {
      margin-left: minPx(24);
    }
  }
}
}
@media screen and (min-width: 1601px) {
.container {
  .content {
    height: maxPx(480);
    padding: maxPx(105) maxPx(240);
    .sub-head {
      font-size: maxPx(50);
      line-height: maxPx(61);
    }
    .sub-des {
      font-size: maxPx(24);
      line-height: maxPx(32);
      //margin: maxPx(17) maxPx(93) maxPx(110);
    }
  }
  .contact {
    height: maxPx(250);
    box-shadow: maxPx(0) maxPx(6) maxPx(50) maxPx(0) rgba(0, 0, 0, 0.09);
    border-radius: maxPx(8);
    top: maxPx(-125);
    .con-title {
      font-size: maxPx(38);
      line-height: maxPx(53);
    }
    .con-des {
      font-size: maxPx(28);
      line-height: maxPx(40);
      padding-top: maxPx(17);
    }
  }
  .footer {
    .copyright{
        padding-left: axP;
    }
    height: maxPx(61);
    line-height: maxPx(61);
    font-size: maxPx(14);
    a {
      margin-left: maxPx(24);
    }
  }
}
}
</style>
